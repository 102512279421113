<template>
  <div>
    <swiper :options="swiperOption" ref="videoSwiper" v-if="initOrNot">
      <swiper-slide v-for="(item, index) in mediaNews" :key="index">
        <video
          v-if="item.type == 2"
          :src="item.image"
          controls
          muted
          @ended="endVideo(index)"
          class="multimedia"
        ></video>
        <img v-else :src="item.image" class="multimedia" />
      </swiper-slide>
      <div
        class="swiper-pagination"
        v-for="(item, index) in mediaNews"
        :key="index"
        slot="pagination"
      ></div>
    </swiper>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：轮播图
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-08-26 15:16
 */

import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";

export default {
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      mediaLastIndex: 0,
      swiperOption: {
        speed: 1000,
        loop: false,
        observer: true,
        observeParents: true,
        autoplayDisableOnInteraction: false,
        allowTouchMove: false,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        on: {
          slideChangeTransitionEnd: () => {
            this.slideChangeTransitionEndHandle();
          },
          slideChangeTransitionStart: () => {
            this.slideChangeTransitionStartHandle();
          },
          //控制第一个slide切换
          init: () => {
            this.initHandle();
          },
        },
      },
      initOrNot: false, //mediaNews数据改变的时候确保swiper会init一次
      mediaNews: [
        // 1为视频类2为图片类
        // { url: require('./assets/莫干山1.jpg'), type: 2 },
        // { url: require('./assets/v1.mp4'), type: 1 },
        // { url: require('./assets/v1.mp4'), type: 1 },
        // { url: require('./assets/莫干山1.jpg'), type: 2 }
      ],
    };
  },
  watch: {
    mediaNews: {
      handler(newName, oldName) {
        this.initOrNot = false;
        this.$nextTick(() => {
          this.initOrNot = true;
        });
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.$request({
      url: "/Index/index",
      data: {},
    }).then((res) => {
      console.log("首页", res);
      this.mediaNews = res.adv_list;
    });
  },
  methods: {
    initHandle() {
      let that = this;
      // console.log(this.mediaNews)
      setTimeout(function () {
        console.log(that.$refs.videoSwiper);
        let swiper = that.$refs.videoSwiper.swiper;
        if (that.mediaNews[0]?.type == 1) {
          that.mediaNewsImgHandle(swiper);
        } else {
          document.getElementsByClassName("multimedia")[0]?.play();
        }
      }, 200);
    },
    mediaNewsImgHandle(swiper) {
      let that = this;
      //刚切换到的activeIndex
      let changePointActiveIndex = swiper.activeIndex;
      if (swiper.activeIndex < this.mediaNews.length - 1) {
        setTimeout(function () {
          //要确认changePointActiveIndex是不是还是目前的activeIndex，是的话计时后执行，不是的话不执行
          if (changePointActiveIndex === swiper.activeIndex) {
            swiper.slideNext();
          }
        }, 5000);
      } else {
        setTimeout(function () {
          if (changePointActiveIndex === swiper.activeIndex) {
            swiper.slideTo(0, 0);
          }
        }, 5000);
      }
    },
    slideChangeTransitionStartHandle() {
      let swiper = this.$refs.videoSwiper.swiper;
      if (this.mediaNews[this.mediaLastIndex].type === 1) {
        document.getElementsByClassName("multimedia")[
          this.mediaLastIndex
        ].currentTime = 0;
      } else {
      }
    },
    slideChangeTransitionEndHandle() {
      // console.log('end..')
      let that = this;
      let swiper = that.$refs.videoSwiper.swiper;
      if (this.mediaNews[this.mediaLastIndex].type == 2) {
        document
          .getElementsByClassName("multimedia")
          [this.mediaLastIndex].pause();
      }
      if (this.mediaNews[swiper.activeIndex].type == 1) {
        this.mediaNewsImgHandle(swiper);
      } else {
        document
          .getElementsByClassName("multimedia")
          [swiper.activeIndex].play();
      }

      this.mediaLastIndex = swiper.activeIndex;
    },
    endVideo(index) {
      let swiper = this.$refs.videoSwiper.swiper;
      if (index === swiper.activeIndex) {
        if (swiper.activeIndex < this.mediaNews.length - 1) {
          swiper.slideNext();
          if (this.mediaNews[swiper.activeIndex].type == 2) {
            document
              .getElementsByClassName("multimedia")
              [swiper.activeIndex].play();
          } else {
            this.mediaNewsImgHandle(swiper);
          }
        } else {
          swiper.slideTo(0, 0);
          if (this.mediaNews[swiper.activeIndex].type == 2) {
            document
              .getElementsByClassName("multimedia")
              [swiper.activeIndex].play();
          } else {
            this.mediaNewsImgHandle(swiper);
          }
        }
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.multimedia {
  height: 500px;
}
</style>